import * as amplitude from "@amplitude/analytics-browser";

/**
 * Función reutilizable de PLAVED para rastrear eventos personalizados de los usuarios. Se utilizará para combinar los eventos de la sesión de Replay con los eventos de Amplitude.
 * 
 * @param {string} eventName - Nombre del evento.
 * @param {Object} eventProperties - Propiedades adicionales del evento.
 */
export const trackEvent = (eventName, eventProperties = {}) => {
  try {
    // Obtener propiedades de Session Replay

    // Enviar evento a Amplitude
    amplitude.track(eventName, {
      ...eventProperties,
    });

    console.log(`Evento rastreado: ${eventName}`, { ...eventProperties });
  } catch (error) {
    console.error("Error rastreando el evento:", error);
  }
};